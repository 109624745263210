import React from 'react';
import { View, Image, Heading, Button, Text } from '@aws-amplify/ui-react';
import { useTheme, useAuthenticator } from '@aws-amplify/ui-react';
import logo from '../img/Logo1.png';
import '../css/style.css';
export const components = {
  Header() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Image alt="Logo" src={logo} width={100} />
      </View>
    );
  },

  Footer() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Text color={tokens.colors.neutral[80]}>&copy; All Rights Reserved</Text>
      </View>
    );
  },

  SignIn: {
    Header() {
      const { tokens } = useTheme();

      return (
        <>
          <Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={3}>
            Effettua il Log In
          </Heading>
        </>
      );
    },
    Footer() {
      const { toResetPassword } = useAuthenticator();

      return (
        <View textAlign="center" style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            fontWeight="normal"
            onClick={toResetPassword}
            size="small"
            variation="link"
            width={200}
            style={{ marginBottom: '10px' }}>
            Reset Password
          </Button>
        </View>
      );
    }
  },

  SignUp: {
    Header() {
      const { tokens } = useTheme();

      return (
        <Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={3}>
          Crea un nuovo account
        </Heading>
      );
    },
    Footer() {
      const { toSignIn } = useAuthenticator();

      return (
        <View textAlign="center" style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            fontWeight="normal"
            onClick={toSignIn}
            size="small"
            variation="link"
            class="secondaryBtn"
            width={200}
            style={{ marginBottom: '10px' }}>
            Indietro
          </Button>
        </View>
      );
    }
  },
  ConfirmSignUp: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={3}>
          Inserisci informazioni:
        </Heading>
      );
    },
    Footer() {
      return <Text>Footer Information</Text>;
    }
  },
  SetupTOTP: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={3}>
          Inserisci informazioni:
        </Heading>
      );
    },
    Footer() {
      return <Text>Footer Information</Text>;
    }
  },
  ConfirmSignIn: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={3}>
          Inserisci informazioni:
        </Heading>
      );
    },
    Footer() {
      return <Text>Footer Information</Text>;
    }
  },
  ResetPassword: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={3}>
          Reset Password:
        </Heading>
      );
    },
    Footer() {
      return <Text>Footer Information</Text>;
    }
  },
  ConfirmResetPassword: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={3}>
          Inserisci informazioni:
        </Heading>
      );
    },
    Footer() {
      return <Text>Footer Information</Text>;
    }
  }
};

export const formFields = {
  signIn: {
    username: {
      labelHidden: false,
      placeholder: 'Inserisci Username'
    },
    password: {
      labelHidden: false,
      placeholder: 'Inserisci Password'
    }
  },
  signUp: {
    username: {
      labelHidden: false,
      placeholder: 'Inserisci Username:',
      order: 1
    },
    email: {
      labelHidden: false,
      placeholder: 'Inserisci Email:',
      order: 2
    },
    password: {
      labelHidden: false,
      label: 'Password:',
      placeholder: 'Inserisci Password:',
      isRequired: true,
      order: 3
    },
    confirm_password: {
      labelHidden: true,
      label: 'Conferma Password:',
      order: 4
    }
  },
  forceNewPassword: {
    password: {
      labelHidden: false,
      placeholder: 'Inserisci Password:'
    }
  },
  resetPassword: {
    username: {
      labelHidden: false,
      placeholder: 'Inserisci Username:'
    }
  },
  confirmResetPassword: {
    confirmation_code: {
      labelHidden: false,
      placeholder: 'Inserisci codice di conferma:',
      label: 'Codice di conferma',
      isRequired: false
    },
    confirm_password: {
      labelHidden: false,
      placeholder: 'Inserisci Password:'
    }
  },
  setupTOTP: {
    QR: {
      totpIssuer: 'test issuer',
      totpUsername: 'amplify_qr_test_user'
    },
    confirmation_code: {
      labelHidden: false,
      label: 'New Label',
      placeholder: 'Inserisci codice di conferma:',
      isRequired: false
    }
  },
  confirmSignIn: {
    confirmation_code: {
      labelHidden: false,
      label: 'New Label',
      placeholder: 'Inserisci codice di conferma:',
      isRequired: false
    }
  }
};
