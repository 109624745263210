import React from 'react'
import { Tag } from 'antd'

const StateBadge = ({ state }) => {
  let color
  switch(state){
    case 'Aggiudicato':
      color = 'green';
      break;
  
    case 'Opportunità Rilevata':
      color = 'cyan';
      break;

    case 'Alla firma':
      color = 'blue';
      break;
    
    case 'Rimandato':
      color = 'orange';
      break;

    case 'Rifiutato':
      color = 'red';
      break;
    
    case 'Offerta proposta':
      color = 'grey';
      break;
  }
  return <Tag color={color}>{state}</Tag>
}

export default StateBadge
