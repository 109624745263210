import React, { createContext, useState } from 'react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import { Layout } from 'antd'
import SideMenu from './SideMenu'
import Deals from '../pages/assignments/modules/Deals'
import HeaderOne from './HeaderOne'
import Performances from '../pages/performances/modules/Performances'
import DetailsPerformances from '../pages/performances/modules/DetailsPerformances'
import { formFields, components } from '../datas/dataAuth'
import { Authenticator } from '@aws-amplify/ui-react'
// eslint-disable-next-line import/no-unresolved
import '@aws-amplify/ui-react/styles.css'
import Logo from './Logo'
import styled from 'styled-components'

const { Header, Content, Footer, Sider } = Layout
export const GlobalContext = createContext({})
export const AssignmentsContext = createContext({})
export const FilterPerformancesContext = createContext({})

const App = ({ user }) => {
  const [currentYear, setCurrentYear] = useState('')
  const [assignments, setAssignments] = useState([])
  const [allDeals, setAllDeals] = useState([])
  const [collapsed, setCollapsed] = useState(true)
  const [currentAssi, setCurrentAssi] = useState([])
  const [selectedYear, setSelectedYear] = useState('')
  const [search, setSearch] = useState('')
  const [filteredResults, setFilteredResults] = useState(assignments)
  const [filteredPerformances, setFilteredPerformances] = useState({})

  return (
    <Authenticator formFields={formFields} components={components} hideSignUp={true}>
      {({ user }) => (
        <GlobalContext.Provider
          value={{ currentYear, setCurrentYear, selectedYear, setSelectedYear }}>
          <AssignmentsContext.Provider
            value={{
              assignments,
              setAssignments,
              allDeals,
              setAllDeals,
              currentAssi,
              setCurrentAssi,
              filteredResults,
              setFilteredResults,
              search,
              setSearch
            }}>
            <FilterPerformancesContext.Provider value={{ filteredPerformances, setFilteredPerformances }}>
              <BrowserRouter>
                <Layout style={{ minHeight: '100vh' }}>
                  <StyledSider
                    collapsible
                    collapsed={collapsed}
                    onCollapse={(value) => setCollapsed(value)}>
                    <SideMenu />
                  </StyledSider>
                  <Layout>
                    <Header className="header">
                      <HeaderOne user={user} />
                    </Header>
                    <Content>
                      <div className="site-layout-background content">
                        <Routes>
                          <Route path="/" element={<Navigate to={`/performances`} />}></Route>
                          <Route path="/assignments" element={<Deals />}></Route>
                          <Route path="performances" element={<Performances />}></Route>
                          <Route path="performances/details/:id" element={<DetailsPerformances />}></Route>
                        </Routes>
                      </div>
                    </Content>
                    <Footer></Footer>
                  </Layout>
                </Layout>
              </BrowserRouter>
            </FilterPerformancesContext.Provider>
          </AssignmentsContext.Provider>
        </GlobalContext.Provider>
      )}
    </Authenticator>
  )
}

export default App;

const StyledSider = styled(Sider)`
  .ant-layout-sider-children {
    position: fixed;
  }

  .ant-menu-inline-collapsed {
    width: 80px !important;
  }
`;
