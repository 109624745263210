/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getSals = /* GraphQL */ `
  query GetSals($id: ID!) {
    getSals(id: $id) {
      id
      year
      amount
      assignment_id
      createdAt
      updatedAt
    }
  }
`;
export const listSals = /* GraphQL */ `
  query ListSals($filter: ModelSalsFilterInput, $limit: Int, $nextToken: String) {
    listSals(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        year
        amount
        assignment_id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCustomers = /* GraphQL */ `
  query GetCustomers($id: ID!) {
    getCustomers(id: $id) {
      id
      customer_id
      name
      p_iva
      email
      telephone
      note
      reference
      createdAt
      updatedAt
    }
  }
`;
export const listCustomers = /* GraphQL */ `
  query ListCustomers($filter: ModelCustomersFilterInput, $limit: Int, $nextToken: String) {
    listCustomers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        customer_id
        name
        p_iva
        email
        telephone
        note
        reference
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getProjects = /* GraphQL */ `
  query GetProjects($id: ID!) {
    getProjects(id: $id) {
      id
      code_id
      name
      description
      customer_id
      createdAt
      updatedAt
    }
  }
`;
export const listProjects = /* GraphQL */ `
  query ListProjects($filter: ModelProjectsFilterInput, $limit: Int, $nextToken: String) {
    listProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        code_id
        name
        description
        customer_id
        createdAt
        updatedAt
        deal_value
        id_assignment
      }
      nextToken
    }
  }
`;
export const getAssignmentsProva = /* GraphQL */ `
  query GetAssignmentsProva($id: ID!) {
    getAssignmentsProva(id: $id) {
      id
      id_assignment
      project_id
      description
      note
      payment
      state
      year_entry
      year_start_work
      typology
      confidence
      gu
      assignment_value
      link_estimate
      link_analisys
      revenue
      out_sourcing_cost
      gu_price
      gu_cost
      completion_customer_percentage
      time_prev
      time_remaining
      time_spent
      develop_state
      rejection_reason
      createdAt
      updatedAt
    }
  }
`;
export const listAssignmentsProva = /* GraphQL */ `
  query ListAssignmentsProva(
    $filter: ModelAssignmentsProvaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAssignmentsProva(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        id_assignment
        project_id
        description
        note
        payment
        state
        year_entry
        year_start_work
        typology
        confidence
        gu
        assignment_value
        link_estimate
        link_analisys
        revenue
        out_sourcing_cost
        gu_price
        gu_cost
        completion_customer_percentage
        time_prev
        time_remaining
        time_spent
        develop_state
        rejection_reason
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const getPayment = /* GraphQL */ `
  query GetPayment($id: ID!) {
    getPayment(id: $id) {
      id
      year
      amount
      assignment_id
      createdAt
      updatedAt
    }
  }
`;
export const listPayments = /* GraphQL */ `
  query ListPayments(
    $filter: ModelPaymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPayments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        year
        amount
        assignment_id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAssignments = /* GraphQL */ `
  query GetAssignments($id: ID!) {
    getAssignments(id: $id) {
      id
      id_assignment
      project_id
      typology
      payment
      state
      confidence
      rejection_reason
      year_entry
      year_start_work
      link_estimate
      link_analisys
      description
      note
      gu
      gu_price
      gu_cost
      revenue
      out_sourcing_cost
      time_prev
      time_remaining
      time_spent
      develop_state
      assignment_value
      createdAt
      updatedAt
      assignment_type
      close_date
      contact_person
      created
      lost_time
      next_activity_date
      organization_name
      project
      status
      title
      won_time
      deal_value
      total_deal_value
    }
  }
`;
export const listAssignments = /* GraphQL */ `
  query ListAssignments(
    $filter: ModelAssignmentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAssignments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        id_assignment
        project_id
        typology
        payment
        state
        confidence
        rejection_reason
        year_entry
        year_start_work
        link_estimate
        link_analisys
        description
        note
        gu
        gu_price
        gu_cost
        revenue
        out_sourcing_cost
        time_prev
        time_remaining
        time_spent
        develop_state
        assignment_value
        createdAt
        updatedAt
        assignment_type
        close_date
        contact_person
        created
        lost_time
        next_activity_date
        organization_name
        project
        status
        title
        won_time
        deal_value
        total_deal_value
      }
      nextToken
    }
  }
`;