import { message } from 'antd';
import styled, { css } from 'styled-components';

//Message PopUp onDelete, onCreate, onEdit
export const openMessageSuccess = (messagee) => {
  message.success({
    content: messagee,
    key: 'msg'
  });
};
export const openMessageWarning = (messagee) => {
  message.warning({
    content: messagee,
    key: 'msg'
  });
};
export const openMessageError = (messagee) => {
  message.error({
    content: messagee,
    key: 'msg'
  });
};

//NUMERO IN EURO + PUNTO MIGLIAIA
export const formatNumber = (number) => {
  const newNumber = new Intl.NumberFormat('it-IT', {
    style: 'currency',
    currency: 'EUR',
    maximumFractionDigits: 0
  }).format(number);

  return newNumber;
};

//Buttons for tables
export const TabBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3vh;
  width: 3vh;
  font-size: 1.8vh;
  border-radius: 5px;
  border: none;

  ${(props) =>
    props.edit &&
    css`
      background-color: rgba(230, 218, 0, 0.5);
      color: #666100;
    `}
  ${(props) =>
    props.details &&
    css`
      background-color: rgba(153, 209, 255, 0.5);
      color: #002d52;
    `}
      ${(props) =>
    props.delete &&
    css`
      background-color: rgba(154, 0, 0, 0.5);
      color: #8f1d00;
    `}

    &:hover {
    cursor: pointer;
    transform: scale(0.98);
    transform-origin: center
      ${(props) =>
        props.edit &&
        css`
          background-color: rgba(230, 218, 0, 0.9);
          color: white;
        `}
      ${(props) =>
        props.details &&
        css`
          background-color: rgba(153, 209, 255, 0.9);
          color: white;
        `}
      ${(props) =>
        props.delete &&
        css`
          background-color: rgba(154, 0, 0, 0.8);
          color: white;
        `};
  }
  &:focus {
    transform: scale(0.9);
    ${(props) =>
      props.delete &&
      css`
        background-color: #8f1d00;
        color: white;
      `}
  }
  &:active {
    transform: scale(0.9);
  }
`;

export const ButtonX = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: none;
  padding: 1.5vh 2vh 1.5vh 2vh;

  ${(props) =>
    props.primary &&
    css`
      background-color: #002d52;
      color: white;
    `}
  ${(props) =>
    props.secondary &&
    css`
      background-color: rgba(153, 209, 255, 0.5);
      color: #002d52;
    `}
    ${(props) =>
    props.delete &&
    css`
      padding: 0.75vh 1vh 0.75vh 1vh;
      background-color: rgba(154, 0, 0, 0.5);
      color: #8f1d00;
    `}
  ${(props) =>
    props.csv &&
    css`
      background-color: #0e8f00;
      color: white;
    `}
    ${(props) =>
    props.logOut &&
    css`
      border: none;
      background-color: #8f1d00;
      color: white;
    `}
    ${(props) =>
    props.home &&
    css`
      border: none;
      background-color: rgba(153, 209, 255, 0.9);
      color: white;
    `}
    ${(props) =>
    props.hidden &&
    css`
      display: none;
    `}
    ${(props) =>
    props.alert &&
    css`
      padding: 0.75vh 1vh 0.75vh 1vh;
      background-color: rgba(154, 0, 0, 0.2);
      border: 1px solid rgba(154, 0, 0, 0.6);
      color: #8f1d00;
      width: 50vw;
    `}
    ${(props) =>
    props.profile &&
    css`
      background-color: rgba(230, 218, 0, 0.9);
      color: white;
    `}

    &:hover {
    cursor: pointer;
    transform: scale(0.98);
    transform-origin: center;

    ${(props) =>
      props.primary &&
      css`
        background-color: #001629;
        color: white;
      `}
    ${(props) =>
      props.secondary &&
      css`
        background-color: rgba(153, 209, 255, 0.9);
        color: white;
      `}
      ${(props) =>
      props.delete &&
      css`
        background-color: rgba(154, 0, 0, 0.8);
        color: white;
      `};
    ${(props) =>
      props.csv &&
      css`
        background-color: #0a6600;
        color: white;
        transform: scale(0.99);
        transform-origin: center;
      `}
    ${(props) =>
      props.logOut &&
      css`
        border: none;
        background-color: #8f1d00;
        color: white;
      `}
      ${(props) =>
      props.home &&
      css`
        border: none;
        background-color: rgba(153, 209, 255, 0.9);
        color: white;
      `}
      ${(props) =>
      props.profile &&
      css`
        background-color: rgba(230, 218, 0, 0.9);
        color: white;
      `}
  }

  &:focus {
    transform: scale(0.94);
    ${(props) =>
      props.primary &&
      css`
        background-color: #002d52;
        color: white;
      `}
    ${(props) =>
      props.alert &&
      css`
        transform: scale(1);
      `}
  }
  &:active {
    transform: scale(0.92);
    ${(props) =>
      props.alert &&
      css`
        transform: scale(1);
      `}
  }
`;

export const LoadingDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* height: 80vh; */
`;
