import React from 'react';
import { StockOutlined, EuroOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label
  };
}

export const sideMenuItems = [
  
  getItem(<Link to="/performances">Performances</Link>, '1', <StockOutlined />),
  getItem(<Link to="/assignments">Commesse</Link>, '2', <EuroOutlined />),
]
