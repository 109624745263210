import React, { useContext, useEffect } from 'react';
import { LogoutOutlined, HomeOutlined, UserOutlined, DownOutlined } from '@ant-design/icons';
import { Popover, Select, Space } from 'antd';
import '../css/style.css';
import { GlobalContext } from './App';
import { Auth } from 'aws-amplify';
import { ButtonX } from '../utils/utils';
import { useNavigate } from 'react-router-dom';
const { Option } = Select;

const HeaderOne = ({ user }) => {
  const { currentYear, setCurrentYear } = useContext(GlobalContext)
  const { selectedYear, setSelectedYear } = useContext(GlobalContext)

  const navigate = useNavigate()

  const signOut = () => {
    Auth.signOut()
      .then((data) => console.log(data))
      .catch((err) => console.log(err));
    // console.log(Auth);
  };

  const onSelect = async (e) => {
    await setSelectedYear(e);
    await setCurrentYear(e);
  }

  // const filterYear = async (currentY) => {
  //   let newAss = [];
  //   assignments.forEach((ass) => {
  //     if (ass.years) {
  //       if (ass.years.includes(currentY)) {
  //         newAss.push(ass);
  //       }
  //     }
  //   });
  //   setCurrentAssi(newAss);
  // };

  let currentDate = new Date();
  let year = String(currentDate.getFullYear());

  const contentLogOut = (
    <div
      style={{
        padding: '5px',
        height: '100px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}>
      <div>Logged in as: {user.username.toUpperCase()}</div>
      <ButtonX
        primary
        style={{
          height: '10px',
          width: '120px'
        }}
        onClick={() => navigate('/')}>
        <HomeOutlined color="white" />
        &nbsp; HOME
      </ButtonX>
      <ButtonX logOut onClick={signOut} style={{ height: '10px', width: '120px' }}>
        <LogoutOutlined color="white" />
        &nbsp; LOG OUT
      </ButtonX>
    </div>
  );

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
      <Select
        style={{ width: 160, margin: 0 }}
        defaultValue={currentYear}
        onSelect={(e) => {
          onSelect(e);
        }}>
        <Option value=''>ALL</Option>
        <Option value={String(Number(year) - 2)}>{Number(year) - 2}</Option>
        <Option value={String(Number(year) - 1)}>{Number(year) - 1}</Option>
        <Option value={String(year)}>{year}</Option>
        <Option value={String(Number(year) + 1)}>{Number(year) + 1}</Option>
        <Option value={String(Number(year) + 2)}>{Number(year) + 2}</Option>
      </Select>

      <Popover placement="bottomLeft" content={contentLogOut} trigger="hover">
        <ButtonX
          primary
          style={{
            position: 'absolute',
            height: '4vh',
            right: '20px',
            backgroundColor: '#001629'
          }}>
          <UserOutlined style={{ fontSize: '18px' }} />
          &nbsp;
          <DownOutlined style={{ fontSize: '10px' }} />
        </ButtonX>
      </Popover>
    </div>
  );
};

export default HeaderOne;
