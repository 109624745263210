import React, { useContext } from 'react'
import {
  Table,
  Input,
  Tooltip,
  Badge,
  Tag
} from 'antd';
import { ZoomInOutlined } from '@ant-design/icons'
import { TabBtn } from '../../../utils/utils'
import { useNavigate } from 'react-router-dom'
const { Search } = Input
import _ from 'lodash'
import { FilterPerformancesContext } from '../../../components/App'

const TabPerformances = ({ data: wonDeals }) => {

  const { filteredPerformances, setFilteredPerformances } = useContext(FilterPerformancesContext)

  const navigate = useNavigate()

  const formatNumber = (number) => {
    const newNumber = new Intl.NumberFormat('it-IT', {
      style: 'currency',
      currency: 'EUR',
      maximumFractionDigits: 0
    }).format(number)
    return newNumber
  }

  const columnsPerformances = [
    {
      title: 'ID',
      dataIndex: 'id ',
      key: 'id',
      fixed: 'left',
      width: 40,
      render: (record, obj) => {
        return obj.id
      },
      sorter: (a, b) => {
        return a.id - b.id
      }
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      fixed: 'left',
      width: 250,
      defaultSortOrder: filteredPerformances.field === 'title' && filteredPerformances.order,
      sorter: (a, b) => {
        if (a.title < b.title) {
          return -1;
        }
        if (a.title > b.title) {
          return 1;
        }
        return 0;
      }
    },
    {
      title: "Organization Name",
    dataIndex: 'organization_name',
    key: 'organization_name',
    width: 120,
    },
    {
      title: 'Stato Sviluppi',
      dataIndex: 'develop_state',
      key: 'develop_state',
      width: 90,
      render: (record) => {
        if (record === 'IN CORSO') {
          return (
            <div>
              {record} <Badge status="processing" />
            </div>
          )
        } else if (record === 'FINITO')
          return (
            <div>
              {record} <Badge status="success" />
            </div>
          )
      },

      filters: [
        {
          text: 'IN CORSO',
          value: 'IN CORSO'
        },
        {
          text: 'FINITO',
          value: 'FINITO'
        }
      ],
      defaultFilteredValue: filteredPerformances.develop_state,
      filterMode: 'tree',
      filterSearch: true,
      onFilter: (value, record) => {
        return record.develop_state == value
      }
    },
    {
      title: 'Valore Progetto',
      dataIndex: 'total_deal_value',
      key: 'total_deal_value',
      width: 100,
      defaultSortOrder: filteredPerformances.field === 'total_deal_value' && filteredPerformances.order,
      render: (record, obj) => {
        return formatNumber(record)
      },
      sorter: (a, b) => {
        return a.total_deal_value - b.total_deal_value
      }
    },
    {
      title: 'Valore Commessa',
      dataIndex: 'deal_value',
      key: 'deal_value',
      width: 100,
      defaultSortOrder: filteredPerformances.field === 'deal_value' && filteredPerformances.order,
      render: (record, obj) => {
        return formatNumber(obj.deal_value)
      },
      sorter: (a, b) => {
        return a.deal_value - b.deal_value
      }
    },
    /*{
      title: 'Valore Commessa Netto',
      dataIndex: 'net_outS',
      key: 'net_outS',
      width: 100,
      render: (record, obj) => {
        return formatNumber(record)
      },
      defaultSortOrder: filteredPerformances.field === 'net_outS' && filteredPerformances.order,
      sorter: (a, b) => {
        const aNetInvoiceValue = a.total - a.out_sourcing_cost
        const bNetInvoiceValue = b.total - b.out_sourcing_cost

        return aNetInvoiceValue - bNetInvoiceValue
      }
    },*/
    /*{
      title: 'Lavorato',
      dataIndex: 'done_job',
      key: 'done_job',
      width: 100,
      render: (record, obj) => {
        return (
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>{formatNumber(record)}</div>
            <div style={{ fontSize: '9px' }}>{`${obj.done_job_percentage}%`}</div>
          </div>
        )
      },
      defaultSortOrder: filteredPerformances.field === 'done_job' && filteredPerformances.order,
      sorter: (a, b) => {
        return a.done_job - b.done_job
      }
    },*/
    /*{
      title: 'Fatturato',
      dataIndex: 'revenue',
      key: 'revenue',
      width: 100,
      render: (record, obj) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div>{formatNumber(record)}</div>
            <div style={{ fontSize: '9px' }}>{`${obj.revenue_percentage}%`}</div>
          </div>
        )
      },
      defaultSortOrder: filteredPerformances.field === 'revenue' && filteredPerformances.order,
      sorter: (a, b) => {
        return a.revenue - b.revenue;
      }
    },*/
    /*{
      title: 'Da Fatturare',
      dataIndex: 'net_value',
      key: 'net_value',
      width: 100,
      render: (record, obj) => {
        return <div> {formatNumber(record)} </div>
      },
      defaultSortOrder: filteredPerformances.field === 'net_value' && filteredPerformances.order,
      sorter: (a, b) => {
        return a.net_value - b.net_value
      }
    },*/
    {
      title: 'Tempo Stimato Gu',
      dataIndex: 'time_prev',
      key: 'time_prev',
      width: 100,
      defaultSortOrder: filteredPerformances.field === 'time_prev' && filteredPerformances.order,
      sorter: (a, b) => {
        return a.time_prev - b.time_prev
      }
    },
    {
      title: 'Tempo Lavorato Gu',
      dataIndex: 'time_spent',
      key: 'time_spent',
      width: 100,
      defaultSortOrder: filteredPerformances.field === 'time_spent' && filteredPerformances.order,
      sorter: (a, b) => {
        return a.time_spent - b.time_spent
      }
    },
    {
      title: 'Tempo Mancante Gu',
      dataIndex: 'time_remaining',
      key: 'time_remaining',
      width: 90,
      defaultSortOrder: filteredPerformances.field === 'time_remaining' && filteredPerformances.order,
      sorter: (a, b) => {
        return a.time_remaining - b.time_remaining
      },
      
    },
    {
      title: 'Tempo Totale Gu',
      dataIndex: 'total_time',
      key: 'total_time',
      width: 100,
      defaultSortOrder: filteredPerformances.field === 'total_time' && filteredPerformances.order,
      sorter: (a, b) => {
        return a.total_time - b.total_time
      },
      render: (record, obj) => {
        return (Math.floor(record));
      }
    },
    {
      title: 'Margine Atteso',
      dataIndex: 'expected_margin',
      key: 'expected_margin',
      width: 110,
      render: (record, obj) => {

        return (
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>{formatNumber(record)}</div>
            <div style={{ fontSize: '9px' }}>{`${obj.expected_margin_percentage}%`}</div>
          </div>
        )
      },
      sorter: (a, b) => {
        return a.expected_margin - b.expected_margin
      }
    },
    {
      title: 'Margine Realizzato',
      dataIndex: 'margin_realized',
      key: 'margin_realized',
      width: 125,
      render: (record, obj) => {

        const percentageCalc = obj.margin_realized_percentage

        let tagColor = ''

        if (percentageCalc < 1) {
          tagColor = 'red';
        } else if (percentageCalc < 10) {
          tagColor = 'magenta';
        } else if (percentageCalc >= 10 && percentageCalc < 15) {
          tagColor = 'volcano';
        } else if (percentageCalc >= 15 && percentageCalc < 20) {
          tagColor = 'gold';
        } else if (!record) {
          tagColor = 'transparent';
        } else tagColor = 'green';

        return {
          children: (
            <>
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}>
                <Tag color={tagColor}>
                  <span>{formatNumber(record)}</span>
                  <span style={{ fontSize: '9px' }}>{`${percentageCalc}%`}</span>
                </Tag>
              </div>
            </>
          )
        }
      }
    }
  ]

  const columns = [
    ...columnsPerformances,
    {
      title: 'Azioni',
      key: 'actions',
      fixed: 'right',
      width: 55,
      render: (record) => {
        const goToDetails = async () => {
          navigate(`details/${record.id}`);
        };
        return (
          <div>
            <Tooltip title="Dettagli">
              <TabBtn details onClick={() => goToDetails()} >
                <ZoomInOutlined />
              </TabBtn>
            </Tooltip>
          </div>
        )
      }
    }
  ]

  const handleChange = (filters, sorter) => {
    const fieldState = _.get(sorter, 'field', null)
    const orderState = _.get(sorter, 'order', null)
    const developState = _.get(filters, 'develop_state', null)
    setFilteredPerformances({
      ...filteredPerformances,
      develop_state: developState,
      field: fieldState,
      order: orderState,
    })
  }



  const searchByCodeOrDesc = (e) => {
    let newArr = []
    _.forEach(wonDeals, deal => {
      if ((_.includes(_.toLower(deal.title), _.toLower(e.target.value))) ||
        (_.includes(_.toLower(deal.id), _.toLower(e.target.value))) ||
        (_.includes(_.toLower(deal.project), _.toLower(e.target.value)))
      ) {
        newArr.push(deal)
      }
    })
    setFilteredPerformances({ ...filteredPerformances, filteredData: { text: e.target.value, data: newArr } })
  }

  return (
    <>
      <Search
        defaultValue={filteredPerformances?.filteredData?.text}
        placeholder="Cerca commessa"
        onKeyUp={(record) => searchByCodeOrDesc(record)}
        style={{
          width: 500,
          marginBottom: '1vh'
        }}
        id="searchByCodeOrDescPerf"
      />
      <Table
        dataSource={_.isNil(_.get(filteredPerformances, 'filteredData.data', null)) ? wonDeals : filteredPerformances?.filteredData?.data}
        columns={columns}
       onChange={handleChange}
        pagination={ false }
        scroll={{
          x: 'calc(590px + 50%)',
          y: 1000
        }}
        bordered
        id="tabPerformances"
        rowClassName="rowPerformances"
      />
    </>
  )
}

export default TabPerformances;
