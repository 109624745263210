import React, { useState, useContext, useEffect } from 'react'
import { Divider, Collapse } from 'antd'
import TabPerformances from '../tables/TabPerformances'
import { AssignmentsContext, GlobalContext, } from '../../../components/App'
import Loader from '../../../components/Loader'
// import PerformanceGraph from './graphs/Graph'
import _ from 'lodash'
import { performancesMarshal } from '../../../API/marshal/marshal'
import { fetchNSetAssignments } from '../../../API/performancesCalls'
import { getWonDealByYear } from '../../../utils/script'
// const { Panel } = Collapse

const Performances = () => {
  const { currentYear, setCurrentYear } = useContext(GlobalContext)
  const { currentAssi, setCurrentAssi } = useContext(AssignmentsContext)
  const [isLoading, setIsLoading] = useState(true)

  // const [graphData, setGraphData] = useState({
  //   labels: [],
  //   datasets: [
  //     {
  //       label: 'Tempo Preventivato',
  //       data: [],
  //       borderColor: '#99D1FF',
  //       backgroundColor: '#99D1FF80',
  //       borderWidth: 1
  //     },
  //     {
  //       label: 'Tempo Impiegato',
  //       data: [],
  //       borderColor: '#002d52',
  //       backgroundColor: '#002d5280',
  //       borderWidth: 1
  //     }
  //   ]
  // })

  // const getAssPerYear = (assignments, payments) => {
  //   let newList = []
  //   let inProgressAssignments = []
  //   _.forEach(assignments, (el) => {
  //     let yearsIdAss =
  //       el.id_assignment[0] + el.id_assignment[1] + el.id_assignment[2] + el.id_assignment[3]
  //     let yearss = yearsIdAss + '_'
  //     let totalPayment = 0
  //     _.forEach(payments, (payment) => {
  //       if (payment.assignment_id === el.id_assignment) {
  //         let contain = yearss.includes(payment.year)
  //         if (contain) {
  //           yearss = yearss + ''
  //           totalPayment = totalPayment + payment.amount
  //         } else {
  //           yearss = yearss + payment.year + '_'
  //           totalPayment = totalPayment + payment.amount
  //         }
  //         el.years = yearss.slice(0, -1)
  //         el.total = totalPayment
  //       }
  //     })
  //     el = { ...el, years: yearss, total: totalPayment }

  //     if ((el.years.includes(currentYear) && el.develop_state !== 'IN CORSO') && el.state === 'AGGIUDICATA') {
  //       newList.push(el)
  //     }
  //     if (el.develop_state === 'IN CORSO' && el.state === 'AGGIUDICATA') {
  //       inProgressAssignments.push(el)
  //     }
  //   })

  //   const concatList = _.concat(_.orderBy(inProgressAssignments, ['createdAt', 'desc']), newList)
  //   return concatList
  // }

  const loadDataForPage = async () => {
    setIsLoading(true)
    const assignments = await fetchNSetAssignments()
    const marshalAssignments = performancesMarshal(assignments)
    const marshalAssignmentsPerYear = getWonDealByYear(marshalAssignments, currentYear)

    setCurrentAssi(marshalAssignmentsPerYear)
    setIsLoading(false)
    // const dataGraph = getGraphData(marshalAssignments)
    // setGraphData(dataGraph)
  }

  useEffect(() => {
    loadDataForPage()
  }, [currentYear])

  // const getGraphData = (assignmentPerYear) => {
  //   let dataPrev = []
  //   let dataSpent = []
  //   let labels = []

  //   _.forEach(assignmentPerYear, (e) => {
  //     labels.push(e.id_assignment)
  //     dataPrev.push(Number(e.time_prev) || 0)
  //     const timeSpent = Number(e.time_spent) + Number(e.time_remaining)
  //     dataSpent.push(timeSpent)

  //   })

  //   const finalData = {
  //     labels: labels,
  //     datasets: [
  //       {
  //         label: 'Tempo Preventivato',
  //         data: dataPrev,
  //         borderColor: '#99D1FF',
  //         backgroundColor: '#99D1FF80',
  //         borderWidth: 1
  //       },
  //       {
  //         label: 'Tempo Impiegato',
  //         data: dataSpent,
  //         borderColor: '#002d52',
  //         backgroundColor: '#002d5280',
  //         borderWidth: 1
  //       }
  //     ]
  //   }
  //   return finalData
  // }

  return isLoading ? (
    <Loader />
  ) : (
    <div className="contentt">
      <Divider className="contentTitle" style={{ marginBottom: '3vh', fontSize: '21px' }}>
        Performances
      </Divider>
      {/* <div>
        <Collapse accordion style={{ marginBottom: '3vh' }}>
          <Panel header="GRAFICO TEMPO STIMATO SU TEMPO IMPIEGATO" key="graphPerformances">
            <PerformanceGraph data={graphData} />
          </Panel>
        </Collapse>
      </div> */}
      <TabPerformances data={currentAssi} style={{ backgroundColor: 'white' }} />
    </div>
  )
}

export default Performances
