import _ from 'lodash'
import moment from 'moment'

export const marshalDeals = (list) => {
  
  var newList = []
  _.forEach(list, el => {
    var year = moment().format('YYYY')
    if (el.status === 'Won') {
      year = moment(el.won_time).format('YYYY')
    }
    if (el.status === 'Lost') {
      year = moment(el.lost_time).format('YYYY')
    }
    var varState = '•'
      switch(el.state){
        case 'recognized_opportunity':
          varState = 'Opportunità Rilevata';
          break;

        case 'awarded':
          varState = 'Aggiudicato';
          break;

        case 'refused':
          varState = 'Rifiutato';
          break;

        case 'to_signed':
          varState = 'Alla Firma';
          break;

        case 'postponed':
          varState = 'Rimandato';
          break;

        case 'proposed_offer':
          varState = 'Offerta proposta';
          break;

      }
    const newDeal = { ...el, year,varState }
    newList.push(newDeal)
  })
  return newList

}