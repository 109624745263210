import React from 'react';
import { LoadingDiv } from '../utils/utils';
import RingLoader from 'react-spinners/RingLoader';

const Loader = ({ height = '80vh' }) => {
  return (
    <LoadingDiv style={{ height: height }}>
      <RingLoader color="#002d52" />
    </LoadingDiv>
  );
};

export default Loader;
