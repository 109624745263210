import React, { useContext, useState } from 'react'
import { API, graphqlOperation } from 'aws-amplify'
import * as queries from '../API/graphql/queries'
import { openMessageError } from '../utils/utils'
import _ from 'lodash'

//LISTA CUSTOMERS
export const fetchCustomersB = async () => {
  try {
    const customersData = await API.graphql(graphqlOperation(queries.listCustomers))
    const customersList = customersData.data.listCustomers.items
    return customersList
  } catch (error) {
    console.log('error fetching customers', error)
    openMessageError('ERROR FETCHING CUSTOMERS!')
  }
}

//LISTA PROJECTS
export const fetchProjectsB = async () => {
  try {
    const projectsData = await API.graphql(graphqlOperation(queries.listProjects))
    const projectsList = projectsData.data.listProjects.items
    return projectsList
  } catch (error) {
    console.log('error fetching projects', error)
    openMessageError('ERROR FETCHING PROJECTS!')
  }
}

//LISTA ASSIGNMENTS
export const fetchAssignmentsB = async () => {
  try {
    const assignmentsData = await API.graphql(graphqlOperation(queries.listAssignments))
    const assignmentsList = assignmentsData.data.listAssignments.items
    return assignmentsList
  } catch (error) {
    console.log('error fetching assignments', error)
    openMessageError('ERROR FETCHING ASSIGNMENTS!')
  }
};

//LISTA SALS
export const fetchSalsB = async () => {
  try {
    // const salsData = await API.graphql(graphqlOperation(queries.listSals));
    const salsData = await API.graphql(graphqlOperation(queries.listPayments))
    const salsList = salsData.data.listPayments.items
    return salsList
  } catch (error) {
    console.log('error fetching sals', error)
    openMessageError('ERROR FETCHING SALS!')
  }
}

// GET ASSIGNMENTS
export const getAssignment = async (iD) => {
  try {
    const moddedCustomer = await API.graphql({
      query: queries.getAssignments,
      variables: { id: iD }
    })
    return moddedCustomer.data.getAssignments

  } catch (error) {
    console.log('error getting assignments', error)
    openMessageError('ERROR GETTING ASSIGNMENTS!')
  }
}

export const getSals = async (idAssignment) => {
  try {
    const salsData = await API.graphql(graphqlOperation(queries.listPayments, {
      filter: {
        or: [{ assignment_id: { eq: idAssignment } }]
      }
    }))
    const salsList = salsData.data.listPayments.items
    return salsList
  } catch (error) {
    console.log('error fetching sals', error)
    openMessageError('ERROR FETCHING SALS!')
  }
}

export const searchDeals = async () => {
  try {
    const dealsData = await API.graphql(
      graphqlOperation(
        queries.listAssignments
      )
    )
    const dealsList = dealsData.data.listAssignments.items
    return dealsList
  } catch (error) {
    console.log('error fetching deals', error)
    openMessageError('ERROR FETCHING DEALS!')
  }
}

