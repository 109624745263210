import React, { useState } from 'react'
import { Modal, Row, Button, Upload } from 'antd'
import { InboxOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import _ from 'lodash'
import Papa from 'papaparse'
import { API } from 'aws-amplify'
import * as mutations from '../API/graphql/mutations'
const { Dragger } = Upload


const StyledButtonRow = styled(Row)`
  width: 100%;
  height: 60px;
  justify-content: flex-end;
  align-items: flex-end;
`
export const ModalImport = ({ open, onCancel, fullListAssignments, setLoading, onFinish }) => {

  const [file, setFile] = useState()
  const [error, setError] = useState(false)

  const props = {
    name: 'file',
    multiple: false,
    maxCount: 1,
    showUploadList: {
      showPreviewIcon: false,
      showRemoveIcon: false,
      showDownloadIcon: false,
    },
    beforeUpload: (file) => {
      setFile(file)
      return false
    }
  }

  function parseImportAssignment(assignmentList) {
    try {
      let finalAssignment = []
      _.forEach(assignmentList, (singleAssignment) => {
        const parsedData = parseSingleAssignment(singleAssignment)
        finalAssignment = _.concat(finalAssignment, parsedData)
      })
      return finalAssignment
    } catch (error) {
      console.log('ERROR', error)
      return null
    }
  }

  function parseSingleAssignment(singleAssignment) {
    let newAssignmentObject = {}
    const assignment = {
      'Deal - ID': { key: 'id', type: String },
      'Deal - Codice Commessa': { key: 'id_assignment', type: String },
      'Deal - Title': { key: 'title', type: String },
      'Deal - Progetto': { key: 'project', type: String },
      'Deal - Value': { key: 'deal_value', type: Number },
      'Deal - Organization': { key: 'organization_name', type: String },
      'Deal - Contact person': { key: 'contact_person', type: String },
      'Deal - Expected close date': { key: 'close_date', type: String },
      'Deal - Next activity date': { key: 'next_activity_date', type: String },
      'Deal - Status': { key: 'status', type: String },
      'Deal - Deal created': { key: 'created', type: String },
      'Deal - Won time': { key: 'won_time', type: String },
      'Deal - Lost time': { key: 'lost_time', type: String },
      'Deal - Tipo Commessa': { key: 'typology', type: String },
      'Deal - Stage': { key: 'state', type: String }
    }

    for (const [keyy, value] of Object.entries(singleAssignment)) {
      switch (assignment[keyy].type) {
        case String: {
          if (!_.isNil(keyy)) {
            newAssignmentObject = _.set(newAssignmentObject, assignment[keyy].key, String(value))
          } else {
            newAssignmentObject = _.set(newAssignmentObject, assignment[keyy].key, null)
          }
          break
        }
        case Number: {
          if (!_.isNil(value)) {
            newAssignmentObject = _.set(newAssignmentObject, assignment[keyy].key, Number(value))
          } else {
            newAssignmentObject = _.set(newAssignmentObject, assignment[keyy].key, null)
          }
          break
        } default: {
          newAssignmentObject = _.set(newAssignmentObject, assignment[keyy].key, value)
        }
      }
    }
    return newAssignmentObject
  }

  const handleUpload = (file) => {
    try {
      if (file) {
        Papa.parse(file, {
          header: true,
          complete: async function (results) {
            const data = results.data
            var parsed = parseImportAssignment(data)
            if (!_.isNil(parsed)) {
              setLoading(true)
              const marshedData = parseAssignment(parsed)
              const removedDeals = _.differenceBy(fullListAssignments, marshedData, 'id')
              for (let i = 0; i < marshedData.length; i++) {
                const singleElement = marshedData[i]
                const singleElementID = _.get(singleElement, 'id', null)
                const findID = _.find(fullListAssignments, (el) => el.id === singleElementID)
                if (!_.isEmpty(findID)) {
                  const updateAss = await API.graphql({
                    query: mutations.updateAssignments,
                    variables: { input: singleElement }
                  })
                } else {
                  const createdAss = await API.graphql({
                    query: mutations.createAssignments,
                    variables: { input: singleElement }
                  })
                }
              }
              setLoading(false)
              onCancel()
              onFinish()
            } else {
              setError(true)
            }
          }
        })
      }
    } catch (error) {
      console.log('ERROR: ', error)
    }
  }

  const parseAssignment = (valuesList) => {
    const list = []
    _.forEach(valuesList, (el) => {
      if (!_.isEmpty(el.id)) {
        const types = _.split(el.typology, ',')
        var newState = 'recognized_opportunity'
        if (el.state === 'Aggiudicato') {
          newState = 'awarded'
        } else if (el.state === 'Rifiutato') {
          newState = 'refused'
        } else if (el.state === 'Alla Firma') {
          newState = 'to_signed'
        } else if (el.state === 'Rimandato') {
          newState = 'postponed'
        } else {
          newState = 'recognized_opportunity'
        }
        const singleAssObject = {
          ...el,
          typology: types,
          state: newState
        }
        list.push(singleAssObject)
      }
    })
    return list
  }

  return (
    <Modal
      title="Modal"
      open={open}
      onCancel={onCancel}
      footer={false}
    >
      {error ? (
        <>
          <p>
            <b>ATTENZIONE!</b>
            <br />
            Il file caricato non è supportato, verificare che il tipo di file sia di tipo <b>.CSV</b>
            <br />
            Grazie
          </p>
          <StyledButtonRow>
            <Button type='primary' onClick={() => onFinish()}>OK</Button>
          </StyledButtonRow>
        </>
      ) : (
        <>
          <Dragger
            {...props}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p>
              IMPORTARE SOLO FILE <b>.CSV</b>
            </p>
            <p className="ant-upload-text">Cliccare o spostare il file in quest'area.</p>
          </Dragger>

          <StyledButtonRow>
            <Button type='primary' onClick={() => handleUpload(file)}>Importa</Button>
            <Button type='secondary' onClick={onCancel}>Cancella</Button>
          </StyledButtonRow>
        </>
      )}
    </Modal>
  )
}

