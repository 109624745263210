import React from 'react';
import _ from 'lodash';
// import { Chart } from 'react-google-charts';
import { Bar } from 'react-chartjs-2';
import { Chart, CategoryScale, LinearScale, BarElement, BarController, Tooltip, Legend } from 'chart.js';


const OrdersGraph = ({ title, data, totalDealValueForDealStage, width = null }) => {
  Chart.register(CategoryScale, LinearScale, BarElement, BarController, Tooltip, Legend);

  const formatLabelNumber = (n) => {
    const parts = n.toString().split('.');
    const numberPart = parts[0];
    const decimalPart = parts[1];
    const thousands = /\B(?=(\d{3})+(?!\d))/g;
    return numberPart.replace(thousands, '.') + (decimalPart ? `,${decimalPart}` : '');
  };

  const newOptions = {
    plugins: {
      legend: {
        position: 'right',
        //display:false,
        labels: {
          filter: function (item, chart) {
            const dataset = _.get(chart, 'datasets[0]', {});
            const data = _.get(dataset, 'data', []);
            return data[item.index] != 0; // empty values
          }
        }
      },

      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.label || '';
            let dataset = context.dataset;
            if (label) {
              label += ': ';
            }

            const total = context.chart.data.datasets.reduce((acc, dataset) => {
              const value = dataset.data[context.dataIndex];
              return acc + (isNaN(value) ? 0 : value);
            }, 0);


            // get the current items value
            const currentValue = context.parsed.y;

            // calculate the precentage based on the total and current item, also this does a rough rounding to give a whole number
            const percentage = total !== 0 ? ((currentValue / total) * 100).toFixed(2) : 0;

            label += `${formatLabelNumber(context.parsed.y)}€ - ${percentage}%`;
            return label;
          }
        }
      },
      scales: {
        x: {
          beginAtZero: true,
        },
        y: {
          beginAtZero: true,
        },
      },
    }

  };

  const newData = _.get(data, 'datasets[0].data', [0]);

  const StyledNoDataOnGraph = () => (
    <div
      style={{
        height: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
      <p>NO DATA</p>
    </div>
  );

  return (
    <div className="graficoDiv" style={_.isNil(width) ? null : { width: '50%' }}>
      <h3>{title}</h3>
      <hr />
      {/* <Doughnut data={newData} />*/}

      {_.isNil(data) || _.isEmpty(data) || _.every(newData, (el) => el === 0) ? (
        <StyledNoDataOnGraph />
      ) : (
        <Bar data={data} options={newOptions} />
      )}
    </div>
  );
};

export default OrdersGraph;
