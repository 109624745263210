/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createSals = /* GraphQL */ `
  mutation CreateSals($input: CreateSalsInput!, $condition: ModelSalsConditionInput) {
    createSals(input: $input, condition: $condition) {
      id
      year
      amount
      assignment_id
      createdAt
      updatedAt
    }
  }
`;
export const updateSals = /* GraphQL */ `
  mutation UpdateSals($input: UpdateSalsInput!, $condition: ModelSalsConditionInput) {
    updateSals(input: $input, condition: $condition) {
      id
      year
      amount
      assignment_id
      createdAt
      updatedAt
    }
  }
`;
export const deleteSals = /* GraphQL */ `
  mutation DeleteSals($input: DeleteSalsInput!, $condition: ModelSalsConditionInput) {
    deleteSals(input: $input, condition: $condition) {
      id
      year
      amount
      assignment_id
      createdAt
      updatedAt
    }
  }
`;
export const createCustomers = /* GraphQL */ `
  mutation CreateCustomers(
    $input: CreateCustomersInput!
    $condition: ModelCustomersConditionInput
  ) {
    createCustomers(input: $input, condition: $condition) {
      id
      customer_id
      name
      p_iva
      email
      telephone
      note
      reference
      createdAt
      updatedAt
    }
  }
`;
export const updateCustomers = /* GraphQL */ `
  mutation UpdateCustomers(
    $input: UpdateCustomersInput!
    $condition: ModelCustomersConditionInput
  ) {
    updateCustomers(input: $input, condition: $condition) {
      id
      customer_id
      name
      p_iva
      email
      telephone
      note
      reference
      createdAt
      updatedAt
    }
  }
`;
export const deleteCustomers = /* GraphQL */ `
  mutation DeleteCustomers(
    $input: DeleteCustomersInput!
    $condition: ModelCustomersConditionInput
  ) {
    deleteCustomers(input: $input, condition: $condition) {
      id
      customer_id
      name
      p_iva
      email
      telephone
      note
      reference
      createdAt
      updatedAt
    }
  }
`;
export const createProjects = /* GraphQL */ `
  mutation CreateProjects($input: CreateProjectsInput!, $condition: ModelProjectsConditionInput) {
    createProjects(input: $input, condition: $condition) {
      id
      code_id
      name
      description
      customer_id
      createdAt
      updatedAt
    }
  }
`;
export const updateProjects = /* GraphQL */ `
  mutation UpdateProjects($input: UpdateProjectsInput!, $condition: ModelProjectsConditionInput) {
    updateProjects(input: $input, condition: $condition) {
      id
      code_id
      name
      description
      customer_id
      createdAt
      updatedAt
    }
  }
`;
export const deleteProjects = /* GraphQL */ `
  mutation DeleteProjects($input: DeleteProjectsInput!, $condition: ModelProjectsConditionInput) {
    deleteProjects(input: $input, condition: $condition) {
      id
      code_id
      name
      description
      customer_id
      createdAt
      updatedAt
    }
  }
`;
export const createAssignmentsProva = /* GraphQL */ `
  mutation CreateAssignmentsProva(
    $input: CreateAssignmentsProvaInput!
    $condition: ModelAssignmentsProvaConditionInput
  ) {
    createAssignmentsProva(input: $input, condition: $condition) {
      id
      id_assignment
      project_id
      description
      note
      payment
      state
      year_entry
      year_start_work
      typology
      confidence
      gu
      assignment_value
      link_estimate
      link_analisys
      revenue
      out_sourcing_cost
      gu_price
      gu_cost
      completion_customer_percentage
      time_prev
      time_remaining
      time_spent
      develop_state
      rejection_reason
      createdAt
      updatedAt
    }
  }
`;
export const updateAssignmentsProva = /* GraphQL */ `
  mutation UpdateAssignmentsProva(
    $input: UpdateAssignmentsInput!
    $condition: ModelAssignmentsProvaConditionInput
  ) {
    updateAssignmentsProva(input: $input, condition: $condition) {
      id
      id_assignment
      project_id
      description
      note
      payment
      state
      year_entry
      year_start_work
      typology
      confidence
      gu
      assignment_value
      link_estimate
      link_analisys
      revenue
      out_sourcing_cost
      gu_price
      gu_cost
      completion_customer_percentage
      time_prev
      time_remaining
      time_spent
      develop_state
      rejection_reason
      createdAt
      updatedAt
    }
  }
`;
export const deleteAssignmentsProva = /* GraphQL */ `
  mutation DeleteAssignmentsProva(
    $input: DeleteAssignmentsProvaInput!
    $condition: ModelAssignmentsProvaConditionInput
  ) {
    deleteAssignmentsProva(input: $input, condition: $condition) {
      id
      id_assignment
      project_id
      description
      note
      payment
      state
      year_entry
      year_start_work
      typology
      confidence
      gu
      assignment_value
      link_estimate
      link_analisys
      revenue
      out_sourcing_cost
      gu_price
      gu_cost
      completion_customer_percentage
      time_prev
      time_remaining
      time_spent
      develop_state
      rejection_reason
      createdAt
      updatedAt
    }
  }
`;

export const createAssignments = /* GraphQL */ `
  mutation CreateAssignments(
    $input: CreateAssignmentsInput!
    $condition: ModelAssignmentsConditionInput
  ) {
    createAssignments(input: $input, condition: $condition) {
      id
      id_assignment
      project_id
      typology
      payment
      state
      confidence
      rejection_reason
      year_entry
      year_start_work
      link_estimate
      link_analisys
      description
      note
      gu
      gu_price
      gu_cost
      revenue
      out_sourcing_cost
      time_prev
      time_remaining
      time_spent
      develop_state
      assignment_value
      createdAt
      updatedAt
      close_date
      contact_person
      created
      lost_time
      next_activity_date
      organization_name
      project
      status
      title
      won_time
      deal_value
      total_deal_value
    }
  }
`;
export const updateAssignments = /* GraphQL */ `
  mutation UpdateAssignments(
    $input: UpdateAssignmentsInput!
    $condition: ModelAssignmentsConditionInput
  ) {
    updateAssignments(input: $input, condition: $condition) {
      id
      id_assignment
      project_id
      typology
      payment
      state
      confidence
      rejection_reason
      year_entry
      year_start_work
      link_estimate
      link_analisys
      description
      note
      gu
      gu_price
      gu_cost
      revenue
      out_sourcing_cost
      time_prev
      time_remaining
      time_spent
      develop_state
      assignment_value
      createdAt
      updatedAt
      close_date
      contact_person
      created
      lost_time
      next_activity_date
      organization_name
      project
      status
      title
      won_time
      deal_value
      total_deal_value
    }
  }
`;
export const deleteAssignments = /* GraphQL */ `
  mutation DeleteAssignments(
    $input: DeleteAssignmentsInput!
    $condition: ModelAssignmentsConditionInput
  ) {
    deleteAssignments(input: $input, condition: $condition) {
      id
      id_assignment
      project_id
      typology
      payment
      state
      confidence
      rejection_reason
      year_entry
      year_start_work
      link_estimate
      link_analisys
      description
      note
      gu
      gu_price
      gu_cost
      revenue
      out_sourcing_cost
      time_prev
      time_remaining
      time_spent
      develop_state
      assignment_value
      createdAt
      updatedAt
      close_date
      contact_person
      created
      lost_time
      next_activity_date
      organization_name
      project
      status
      title
      won_time
      deal_value
      total_deal_value
    }
  }
`;

export const createPayment = /* GraphQL */ `
mutation CreatePayment(
  $input: CreatePaymentInput!
  $condition: ModelPaymentConditionInput
) {
  createPayment(input: $input, condition: $condition) {
    id
    year
    amount
    assignment_id
    createdAt
    updatedAt
  }
}
`;
export const updatePayment = /* GraphQL */ `
mutation UpdatePayment(
  $input: UpdatePaymentInput!
  $condition: ModelPaymentConditionInput
) {
  updatePayment(input: $input, condition: $condition) {
    id
    year
    amount
    assignment_id
    createdAt
    updatedAt
  }
}
`;
export const deletePayment = /* GraphQL */ `
mutation DeletePayment(
  $input: DeletePaymentInput!
  $condition: ModelPaymentConditionInput
) {
  deletePayment(input: $input, condition: $condition) {
    id
    year
    amount
    assignment_id
    createdAt
    updatedAt
  }
}
`;
