import React from 'react'
import _ from 'lodash'
import {
  fetchAssignmentsB,
  fetchCustomersB,
  fetchProjectsB,
  fetchSalsB,
  getAssignment,
  getSals
} from '../apiCalls'

export const fetchNSetCustomers = async () => {
  const customerList = await fetchCustomersB()
  return customerList
}

export const fetchNSetProjects = async () => {
  const projectList = await fetchProjectsB()
  return projectList
}

export const fetchNSetSals = async () => {
  const salList = await fetchSalsB()
  return salList
}

export const fetchNSetAssignments = async () => {
  const assignmentList = await fetchAssignmentsB()
  return assignmentList
}

export const getSingleAssignment = async (iD) => {
  const singleAssignment = await getAssignment(iD)
  return singleAssignment
}

export const fetchSalsByAssignment = async (iD) => {
  const salsList = await getSals(iD)
  return salsList
}


