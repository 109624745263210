import _ from 'lodash'
import moment from 'moment'

export const performancesMarshal = (value) => {
  var list = []
  var marsh
  if (_.isArray(value)) {
    for (let i = 0; i < value.length; i++) {
      const singleValue = value[i]
      const newMarsh = marshValue(singleValue)
      if (singleValue.status === 'Won') {
        list.push(newMarsh)
      }
      marsh = list
    }
  } else {
    marsh = marshValue(value)
  }
  return marsh
}

const marshValue = (value) => {
  const dealTotal = !_.isNil(value.total_deal_value) ? value.total_deal_value : 0
  const total = value.deal_value
  const timeSpent = !_.isNil(value.time_spent) ? value.time_spent : 0
  const timeRemaining = !_.isNil(value.time_remaining) ? value.time_remaining : 0
  const timePrev = !_.isNil(value.time_prev) ? value.time_prev : 0
  const revenue = !_.isNil(value.revenue) ? value.revenue : 0
  const outSourcingCost = !_.isNil(value.out_sourcing_cost) ? value.out_sourcing_cost : 0
  const netOutS = dealTotal - outSourcingCost
  const guCost = !_.isNil(value.gu_cost) ? value.gu_cost : 0
  const guPrice = !_.isNil(value.gu_price) ? value.gu_price : 0
  const extraMargin = (netOutS - (timeSpent + timeRemaining) * guCost) - (netOutS - timePrev * guCost)
  const extraMarginRendered = extraMargin > 0 ? extraMargin : 0
  const extraMarginPercentageCalculation = ((((netOutS - (timeSpent + timeRemaining) * guCost) - (netOutS - timePrev * guCost)) / netOutS) * 100).toFixed(2)
  const extraMarginPercentageRendered = extraMarginPercentageCalculation > 0 ? extraMarginPercentageCalculation : '0.00'
  const yearWon = moment(value.won_time).format('YYYY')

  const copyValue = {
    ...value,
    total_deal_value: dealTotal,
    total,
    out_sourcing_cost: outSourcingCost,
    net_outS: netOutS,
    time_prev: timePrev,
    time_remaining: timeRemaining,
    time_spent: timeSpent,
    done_job: timeSpent * guPrice + value.out_sourcing_cost,
    done_job_percentage: netOutS === 0 ? (0).toFixed(2) : (((timeSpent * guPrice + value.out_sourcing_cost) / netOutS) * 100).toFixed(2),
    net_value: total - revenue,
    total_time: (timeSpent + timeRemaining).toFixed(2),
    revenue,
    gu_cost: guCost,
    gu_price: guPrice,
    expected_margin: netOutS - timePrev * guCost,
    expected_margin_percentage: netOutS === 0 ? (0).toFixed(2) : (((netOutS - timePrev * guCost) / netOutS) * 100).toFixed(2),
    margin_realized: netOutS - (timeSpent + timeRemaining) * guCost,
    margin_realized_percentage: netOutS === 0 ? (0).toFixed(2) : (((netOutS - (timeSpent + timeRemaining) * guCost) / netOutS) * 100).toFixed(2),
    production_time: (timeSpent + timeRemaining).toFixed(2),
    time_remaining_from_estimate: (timePrev - timeSpent).toFixed(2),
    value_end_prod: (timeSpent + timeRemaining) * guPrice,
    cost_end_prod: (timeSpent + timeRemaining) * guCost,
    extra_margin: extraMarginRendered,
    extra_margin_percentage: extraMarginPercentageRendered,
    revenue_percentage: ((revenue / total) * 100).toFixed(2) + '%',
    year: yearWon
  }
  return copyValue
}