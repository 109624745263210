import React from 'react'
import _ from 'lodash'
import { formatNumber } from '../utils/utils.js'
import { Tag } from 'antd';

const LabelSpanColor = ({ numericValue, percentage }) => {
  const percentageCalc = percentage;
  let tagColor = ''

  if (!_.isNil(percentage)) {
    if (!numericValue) {
      tagColor = 'transparent';
    } else if (percentageCalc < 1) {
      tagColor = 'red';
    } else if (percentageCalc < 10) {
      tagColor = 'magenta';
    } else if (percentageCalc < 15) {
      tagColor = 'volcano';
    } else if (percentageCalc < 20) {
      tagColor = 'gold';
    } else tagColor = 'green';
  }

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}
    >
      <Tag color={tagColor}>
        <span>{formatNumber(numericValue)}</span>
        <span style={{ fontSize: '9px' }}>{`${percentageCalc}%`}</span>
      </Tag>
    </div>
  )
}
export default LabelSpanColor