import React, { useEffect, useState } from 'react'
import { Col, Divider, Row, Form, Select, Badge, Popover } from 'antd'
import { Button, InputNumber, Input } from 'antd'
import { ArrowLeftOutlined, SaveOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import { API } from 'aws-amplify'
import { ButtonX, openMessageSuccess } from '../../../utils/utils'
import * as mutations from '../../../API/graphql/mutations'
import _ from 'lodash'
import Loader from '../../../components/Loader'
import { getSingleAssignment } from '../../../API/performancesCalls'
import { performancesMarshal } from '../../../API/marshal/marshal'
import LabelSpan from '../../../components/LabelSpan'
import LabelSpanColor from '../../../components/LabelSpanColor'

const { Option } = Select

const DetailsPerformances = () => {
  const [currentAss, setCurrentAss] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [isEditing, setIsEditing] = useState(false)
  const [refresh, setRefresh] = useState(false)

  const getAndSetCurrentAssignment = async () => {

    const iD = window.location.pathname.substring(22)
    let moddedCustomer = await getSingleAssignment(iD)

    const marshedModdedCustomer = performancesMarshal(moddedCustomer)

    setCurrentAss(marshedModdedCustomer)
  }

  const setStartLoading = async () => {
    setIsLoading(true)
  }
  const setEndLoading = async () => {
    setIsLoading(false)
  }

  async function executeTasks2() {
    await setStartLoading()
    await executeTask()
    await setEndLoading()
  }
  async function executeTask() {
    await getAndSetCurrentAssignment()
  }

  useEffect(() => {
    executeTasks2()
  }, [window.location.pathname, refresh])

  if (isEditing) {
    document.querySelector('.detailsDivForm').classList.add('editingClass')
  }

  const EditAssignment = async (values) => {
    const moddedProject = await API.graphql({
      query: mutations.updateAssignments,
      variables: { input: values }
    })
    openMessageSuccess('Performance Modificata!')
    setRefresh(!refresh)
  }

  return (
    isLoading ? (
      <Loader />
    ) : (
      <div>
        <Divider className="contentTitle" style={{ marginBottom: '3vh', fontSize: '21px' }}>
          Dettagli Performances
        </Divider>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div className="detailsDiv">
            <Link
              to="/performances"
              onClick={() =>
                document.querySelector('.detailsDivForm').classList.remove('editingClass')
              }>
              <Button
                style={{
                  backgroundColor: 'transparent',
                  color: 'black',
                  width: 'auto',
                  height: 'auto',
                  border: 'none'
                }}>
                <ArrowLeftOutlined style={{ fontSize: '3vh' }} />
              </Button>
            </Link>
            <Row style={{ marginTop: '-3vh', display: 'flex', justifyContent: 'center' }}>
              <Col>
                <Form
                  name="complex-form"
                  className="detailsDivForm"
                  onFinish={(values) => {
                    setIsEditing(false);
                    document.querySelector('.detailsDivForm').classList.remove('editingClass');
                    let newValues = { ...values }
                    let toSendValues = {
                      id: newValues.id,
                      completion_customer_percentage: newValues.completion_customer_percentage,
                      revenue: newValues.revenue,
                      out_sourcing_cost: newValues.out_sourcing_cost,
                      gu_cost: newValues.gu_cost,
                      gu_price: newValues.gu_price,
                      time_prev: newValues.time_prev,
                      time_spent: newValues.time_spent,
                      time_remaining: newValues.time_remaining,
                      develop_state: newValues.develop_state,
                      total_deal_value: newValues.total_deal_value
                    }
                    EditAssignment(toSendValues)
                  }}
                  wrapperCol={{
                    span: 20
                  }}
                  initialValues={{
                    ...currentAss
                  }}
                  autoComplete="off"
                  key={currentAss.id}
                  style={{ width: '70vw' }}
                >
                  <div style={{ width: '100%', }}>
                    <div
                      style={{ fontSize: '21px', marginBottom: '2vh' }}>
                      Generali
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                      <div style={{ marginRight: '2vw' }}>
                        <label htmlFor="id">ID</label>
                        <Form.Item name="id">
                          <Input
                            style={{
                              width: 200,
                              fontSize: '15px',
                              border: 'none',
                              backgroundColor: 'transparent'
                            }}
                            readOnly="readonly"
                            name="id"
                          />
                        </Form.Item>
                      </div>
                      <div style={{ marginRight: '2vw' }}>
                        <label htmlFor="develop_state">Stato Sviluppi:</label>
                        <Form.Item name="develop_state">
                          <Select
                            name="develop_state"
                            style={{
                              width: 200,
                              fontSize: '15px'
                            }}
                            onChange={() => setIsEditing(true)}
                          >
                            <Option value="IN CORSO">IN CORSO</Option>
                            <Option value="FINITO">FINITO</Option>
                          </Select>
                        </Form.Item>
                      </div>
                      <div style={{ marginRight: '2vw' }}>
                        <label htmlFor="title">Title</label>
                        <Form.Item name="title">
                          <Input
                            style={{
                              width: 300,
                              fontSize: '15px',
                              border: 'none',
                              backgroundColor: 'transparent'
                            }}
                            readOnly="readonly"
                            name="title"
                          />
                        </Form.Item>
                      </div>
                      <div>
                        <label htmlFor="deal_value">Valore Commessa</label>
                        <Form.Item name="deal_value">
                          <LabelSpan numericValue={currentAss.deal_value} />
                        </Form.Item>
                      </div>
                    </div>
                  </div>

                  <div style={{ width: '100%' }}>
                    <div
                      style={{ fontSize: '21px', marginBottom: '2vh' }}>
                      {' '}
                      Valore, Lavorato e Fatturato
                    </div>
                    <Row>
                      <Col span={4}>
                        <label htmlFor="total_deal_value">Valore Progetto</label>
                        <Form.Item name="total_deal_value">
                          <InputNumber
                            style={{
                              width: 200,
                              fontSize: '15px'
                            }}
                            name="total_deal_value"
                            formatter={(value) => `€ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            onChange={() => setIsEditing(true)}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={4} offset={1}>
                        <label htmlFor="net_outS">Valore Commessa Netto:</label>
                        <Form.Item name="net_outS">
                          <LabelSpan numericValue={currentAss.net_outS} />
                        </Form.Item>
                      </Col>
                      <Col span={4} offset={1}>
                        <label htmlFor="done_job">Lavorato:</label>
                        <Form.Item name='done_job'>
                          <LabelSpan numericValue={currentAss.done_job} percentage={currentAss.done_job_percentage} />
                        </Form.Item>
                      </Col>
                      <Col span={3}>
                        <label htmlFor="revenue">Fatturato:</label>
                        <Form.Item name="revenue" >
                          <InputNumber
                            style={{
                              width: '100%',
                              fontSize: '15px'
                            }}
                            name="revenue"
                            onChange={() => setIsEditing(true)}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                        <label htmlFor='revenue_percentage' style={{ visibility: 'hidden' }}>ghost label</label>
                        <Form.Item name='revenue_percentage' style={{ marginLeft: '-20px' }}>
                          <Input
                            style={{
                              width: 200,
                              fontSize: '15px',
                              border: 'none',
                              backgroundColor: 'transparent'
                            }}
                            readOnly="readonly"
                            name="revenue_percentage"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={4} offset={1}>
                        <label htmlFor="net_value">Da fatturare:</label>
                        <Form.Item name="net_value">
                          <LabelSpan numericValue={currentAss.net_value} />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={5}>
                        <label htmlFor="gu_price">Prezzo GU:</label>
                        <Form.Item name="gu_price">
                          <InputNumber
                            style={{
                              width: 200,
                              fontSize: '15px'
                            }}
                            name="gu_price"
                            onChange={() => setIsEditing(true)}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={5}>
                        <label htmlFor="gu_cost">Costo GU:</label>
                        <Form.Item name="gu_cost">
                          <InputNumber

                            style={{
                              width: 200,
                              fontSize: '15px'
                            }}
                            name="gu_cost"
                            onChange={() => setIsEditing(true)}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={5}>
                        <label htmlFor="out_sourcing_cost">Costi OutSourcing:</label>
                        <Form.Item name="out_sourcing_cost">
                          <InputNumber
                            style={{
                              width: 200,
                              fontSize: '15px'
                            }}
                            name="out_sourcing_cost"
                            onChange={() => setIsEditing(true)}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>

                  <div style={{ width: '100%' }}>
                    <div
                      style={{ fontSize: '21px', marginBottom: '2vh' }}>
                      Tempistiche (GU)
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        flexDirection: 'column'
                      }}>
                      <Row>
                        <Col span={4}>
                          <div style={{ marginRight: '2vw' }}>
                            <label htmlFor="time_prev">Tempo Stimato:</label>
                            <Form.Item name="time_prev">
                              <InputNumber
                                style={{
                                  width: 200,
                                  fontSize: '15px'
                                }}
                                name="time_prev"
                                step="0.01"
                                onChange={() => setIsEditing(true)}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col span={4} offset={1}>
                          <div>
                            <label htmlFor="time_remaining_from_estimate">
                              Tempo Residuo da Stima:
                            </label>
                            <Form.Item name="time_remaining_from_estimate">
                              <Input
                                style={{
                                  width: 200,
                                  fontSize: '15px',
                                  border: 'none',
                                  backgroundColor: 'transparent'
                                }}
                                readOnly="readonly"
                                name="time_remaining_from_estimate"
                              />
                            </Form.Item>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={4}>
                          <div style={{ marginRight: '2vw' }}>
                            <label htmlFor="time_spent">Tempo Lavorato:</label>
                            <Form.Item name="time_spent">
                              <InputNumber
                                style={{
                                  width: 200,
                                  fontSize: '15px'
                                }}
                                name="time_spent"
                                step="0.01"
                                onChange={() => setIsEditing(true)}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col span={4} offset={1}>
                          <div style={{ marginRight: '2vw' }}>
                            <label htmlFor="time_remaining">Tempo Mancante:</label>
                            <Form.Item name="time_remaining">
                              <InputNumber
                                style={{
                                  width: 200,
                                  fontSize: '15px'
                                }}
                                name="time_remaining"
                                step="0.01"
                                onChange={() => setIsEditing(true)}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col span={4} offset={1}>
                          <div>
                            <label htmlFor="production_time">Tempo Totale Produzione:</label>
                            <Form.Item name="production_time">
                              <Input
                                style={{
                                  width: 200,
                                  fontSize: '15px',
                                  border: 'none',
                                  backgroundColor: 'transparent'
                                }}
                                readOnly="readonly"
                                name="production_time"
                              />
                            </Form.Item>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>

                  <div
                    style={{
                      width: '100%'
                    }}>
                    <div
                      style={{ fontSize: '21px', marginBottom: '2vh' }}>
                      Performances al netto dell'OutSourcing
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        flexDirection: 'column'
                      }}>
                      <Row>
                        <Col span={5}>
                          <div style={{ marginRight: '2vw' }}>
                            <label htmlFor="value_end_prod">Valore al Termine Produzione:</label>
                            <Form.Item name="value_end_prod">
                              <LabelSpan numericValue={currentAss.value_end_prod} />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col span={4}>
                          <div>
                            <label htmlFor="cost_end_prod">Costo al Termine Produzione:</label>
                            <Form.Item name="cost_end_prod">
                              <LabelSpan numericValue={currentAss.cost_end_prod} />
                            </Form.Item>
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col span={5}>
                          <div style={{ marginRight: '2vw' }}>
                            <label htmlFor="expected_margin">Margine Atteso:</label>
                            <Form.Item name="expected_margin">
                              <LabelSpan numericValue={currentAss.expected_margin} percentage={currentAss.expected_margin_percentage} />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col span={5}>
                          <div style={{ marginRight: '2vw' }}>
                            <label htmlFor="margin_realized">Margine Realizzato:</label>
                            <Form.Item name="margin_realized">
                              <LabelSpanColor numericValue={currentAss.margin_realized} percentage={currentAss.margin_realized_percentage} />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col span={4} offset={1}>
                          <div style={{ marginRight: '2vw' }}>
                            <label htmlFor="extra_margin">Extra-Margine:</label>
                            <Form.Item name="extra_margin">
                              <LabelSpan numericValue={currentAss.extra_margin} percentage={currentAss.extra_margin_percentage} />
                            </Form.Item>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>


                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'column'
                    }}>
                    <ButtonX
                      alert
                      hidden={!isEditing}
                      onClick={() => {
                      }}
                      className="btnsAdd"
                      type="button">
                      Attenzione, modifiche non salvate
                    </ButtonX>
                    <Form.Item name="submit" style={{ marginTop: '3vh', marginBottom: '3vh' }}>
                      <ButtonX primary hidden={!isEditing} htmlType="submit" className="btnsAdd">
                        <SaveOutlined />
                        &nbsp; Salva Modifiche
                      </ButtonX>
                    </Form.Item>
                  </div>
                </Form>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    )
  );
};

export default DetailsPerformances;
