import React, { useState, useEffect, useContext } from 'react'
import { ModalImport } from '../../../components/ModalImport'
import { Button, Table, Input } from 'antd'
import { dealColumns } from './dealColumns'
import { GlobalContext } from '../../../components/App'
import { SyncOutlined } from '@ant-design/icons'
import Loader from '../../../components/Loader'
import { searchDeals } from '../../../API/apiCalls'
import { AssignmentsContext } from '../../../components/App'
import _ from 'lodash'
import { marshalDeals } from '../../../API/marshal/marshalDeals'
import { getDealByYear } from '../../../utils/script'
import { CSVLink } from 'react-csv'
import { headersCSV } from '../models/models'
import { workCSV } from '../../../utils/script'
import { ButtonX } from '../../../utils/utils'
import OrdersGraph from './graphs/Graph'
import { Collapse } from 'antd';
import { Chart, CategoryScale, LinearScale, BarElement, BarController, Tooltip, Legend } from 'chart.js';

Chart.register(CategoryScale, LinearScale, BarElement, BarController, Tooltip, Legend);

const { Panel } = Collapse;

const { Search } = Input

const Deals = () => {
  const [isLoading, setIsLoading] = useState(true)
  const { currentYear, setCurrentYear } = useContext(GlobalContext)
  const [modalDragNDrop, setModalDragNDrop] = useState({ open: false })
  const { search, setSearch, filteredResults, setFilteredResults, allDeals, setAllDeals } = useContext(AssignmentsContext)
  const [filterByYear, setFilterByYear] = useState([])
  const [dataCSV, setDataCSV] = useState([])
  const [totalDealValueForStatus, setTotalDealValueForStatus] = useState(0)
  const [totalDealValueForDealStage, setTotalDealValueForDealStage] = useState(0)

  useEffect(() => {
    fetchDeals()
  }, [currentYear])

  const fetchDeals = async () => {
    setIsLoading(true)

    const listAssignment = await searchDeals()
    const marshalDealsResult = marshalDeals(listAssignment)
    const newFilterByYear = getDealByYear(marshalDealsResult, currentYear)
    const dataForCSV = workCSV(newFilterByYear, currentYear)

    if (_.isEmpty(search)) {
      setFilteredResults(newFilterByYear)
    } else {
      const newArr = []
      _.forEach(newFilterByYear, (item) => {
        if (_.includes(_.toLower(item.contact_person), _.toLower(search)) || (_.includes(_.toLower(item.title), _.toLower(search)))) {
          newArr.push(item)
        }
      })
      setFilteredResults(newArr)
    }

    const totalValueByStatus = _.reduce(
      _.groupBy(newFilterByYear, 'status'),
      (result, group, status) => {
        result[status] = _.sumBy(group, 'deal_value');
        return result;
      },
      {}
    );
    setTotalDealValueForStatus(totalValueByStatus);

    const totalValueByDealStage = _.reduce(
      _.groupBy(newFilterByYear, 'varState'),
      (result, group, state) => {
        result[state] = _.sumBy(group, 'deal_value');
        return result;
      },
      {}
    );
    setTotalDealValueForDealStage(totalValueByDealStage);


    setAllDeals(listAssignment)
    setFilterByYear(newFilterByYear)
    setDataCSV(dataForCSV)
    setIsLoading(false)
  }

  const csvLink = {
    headers: headersCSV,
    data: dataCSV,
    filename: 'csvCRM.csv'
  };

  const textualFilter = (e) => {
    let newArr = []
    setSearch(e.target.value)
    if (_.isEmpty(e.target.value) || _.isNil(e.target.value)) {
      setFilteredResults(filterByYear)
    } else {
      _.forEach(filterByYear, (item) => {
        if ((_.includes(_.toLower(item.contact_person), _.toLower(e.target.value))) ||
          (_.includes(_.toLower(item.title), _.toLower(e.target.value))) ||
          (_.includes(_.toLower(item.id), _.toLower(e.target.value))) ||
          (_.includes(_.toLower(item.project), _.toLower(e.target.value))) ||
          (_.includes(_.toLower(item.organization_name), _.toLower(e.target.value)))
        ) {
          newArr.push(item)
        }
      })
      setFilteredResults(newArr)
    }
  }

  function applySeparator(numericAmount) {
    if (typeof numericAmount !== 'number') {
      return numericAmount;
    }
    return numericAmount.toLocaleString();
  }

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <div className="searchFilterDiv" style={{ alignItems: 'center', justifyContent: 'space-between' }}>
        <Search
          placeholder="Cerca commessa"
          onChange={(event) => textualFilter(event)}
          defaultValue={search}
          style={{
            width: 500
          }}
          id="searchByCodeDeals"
        />

        <div style={{ display: 'flex', alignItems: 'center' }}>
          <ButtonX
            csv
            style={{
              padding: '0',
              marginRight: '5px',
              height: '50%'
            }}>
            <CSVLink
              {...csvLink}
              separator={"-"}
              enclosingCharacter={` `}
              style={{
                color: 'white',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '2px',
                border: 'none',
                padding: '5px',
                backgroundColor: '#13c2c2'
              }}>
              Download CSV
            </CSVLink>
          </ButtonX>

          <Button
            type='primary'
            onClick={() => { setModalDragNDrop({ open: true }) }}
          >
            Synch <SyncOutlined />
          </Button>
        </div>

      </div>
      {/* Graph */}
      <div className="graphs for status">
        <Collapse >
          <Panel header="Totali commesse for status" key="1" style={{ marginBottom: '16px' }}>
            <div style={{ width: '70%', height: '70%' }}>
              <OrdersGraph title='Totali commesse for status' data={{
                labels: ['Status'],
                datasets: [
                  {
                    label: `Open: ${applySeparator(_.get(totalDealValueForStatus, 'Open', 0))} €`,
                    data: [_.get(totalDealValueForStatus, 'Open', 0)],
                    backgroundColor: ['#0000FF']
                  },
                  {
                    label: `Won: ${applySeparator(_.get(totalDealValueForStatus, 'Won', 0))} €`,
                    data: [_.get(totalDealValueForStatus, 'Won', 0)],
                    backgroundColor: ['#ADFF2F']
                  },
                  {
                    label: `Lost: ${applySeparator(_.get(totalDealValueForStatus, 'Lost', 0))} €`,
                    data: [_.get(totalDealValueForStatus, 'Lost', 0)],
                    backgroundColor: ['#FF0000']
                  },

                ]
              }}
              />
            </div>
          </Panel>
        </Collapse>
      </div>
      <div className="graphs for deal stage">
        <Collapse style={{ width: '100 px', overflow: 'auto' }}>
          <Panel header="Totali commesse for deal stages" key="1" style={{ marginBottom: '16px' }}>
            <div style={{ width: '70%', height: '70%' }}>
              <OrdersGraph

                title='Totali commesse for deal stages'
                data={{
                  labels: ['Deal Stage'],
                  datasets: [
                    {
                      label: `Opportunità Rilevata: ${applySeparator(_.get(totalDealValueForDealStage, 'Opportunità Rilevata', 0))} €`,
                      data: [_.get(totalDealValueForDealStage, 'Opportunità Rilevata', 0)],
                      backgroundColor: [
                        '#00FFFF',//colore per opportunità rilevata
                      ],
                    },
                    {
                      label: `Offerta Proposta: ${applySeparator(_.get(totalDealValueForDealStage, 'Offerta Proposta', 0))} €`,
                      data: [_.get(totalDealValueForDealStage, 'Offerta Proposta', 0)],
                      backgroundColor: [
                        '#DCDCDC',//colore per Offerta Proposta
                      ],
                    },
                    {
                      label: `Alla Firma: ${applySeparator(_.get(totalDealValueForDealStage, 'Alla Firma', 0))} €`,
                      data: [_.get(totalDealValueForDealStage, 'Alla Firma', 0)],
                      backgroundColor: [
                        '#0000CD',//colore per Alla Firma
                      ],
                    },
                    {
                      label: `Aggiudicato: ${applySeparator(_.get(totalDealValueForDealStage, 'Aggiudicato', 0))} €`,
                      data: [_.get(totalDealValueForDealStage, 'Aggiudicato', 0)],
                      backgroundColor: [
                        '#ADFF2F',//colore per aggiudicato
                      ],
                    },
                    {
                      label: `Rimandato: ${applySeparator(_.get(totalDealValueForDealStage, 'Rimandato', 0))} €`,
                      data: [_.get(totalDealValueForDealStage, 'Rimandato', 0)],
                      backgroundColor: [
                        '#FFA500',//colore per rimandato
                      ],
                    },
                    {
                      label: `Rifiutato: ${applySeparator(_.get(totalDealValueForDealStage, 'Rifiutato', 0))} €`,
                      data: [_.get(totalDealValueForDealStage, 'Rifiutato', 0)],
                      backgroundColor: [
                        '#FF0000',//colore per Rifiutato
                      ],
                    },
                  ],
                }}
              />
            </div>
          </Panel>
        </Collapse>

      </div>

      <Table
        bordered
        dataSource={filteredResults}
        columns={dealColumns}
        style={{ backgroundColor: 'white' }}
        className={`tableAssignments table${currentYear}`}
        id="tabOrders"
        pagination={{ pageSize: 500 }}
        scroll={{
          x: 1300,
        }}
      />
      <ModalImport
        setLoading={setIsLoading}
        fullListAssignments={allDeals}
        open={modalDragNDrop.open}
        onCancel={() => setModalDragNDrop({ open: false })}
        onFinish={() => fetchDeals()}
      />
    </>
  )
}

export default Deals