import React from 'react'
import _ from 'lodash'
import { formatNumber } from '../utils/utils.js'

const LabelSpan = ({ numericValue, percentage }) => {
  return (
    <span style={{
      width: 200,
      fontSize: '15px',
      border: 'none',
      backgroundColor: 'transparent'
    }}>
      {formatNumber(numericValue)} {percentage && (`| ${percentage}%`)}
    </span>
  )
}

export default LabelSpan