import _ from 'lodash'
import moment from 'moment'

// USATO IN Deals.js
export const getDealByYear = (marshedResults, currentYear) => {
  var newListByYear = []
  var openDealsOnCurrentYear = []
  const actualYear = moment().format('YYYY')
  if (!_.isEmpty(currentYear)) {
    _.forEach(marshedResults, el => {
      if ((el.year === currentYear) && (el.status !== 'Open')) {
        newListByYear.push(el)
      }
      if ((el.year === actualYear) && (el.status === 'Open')) {
        openDealsOnCurrentYear.push(el)
      }
    })
  } else {
    newListByYear = marshedResults
  }
  var finalData = []
  if (currentYear === actualYear) {
    finalData = _.concat(newListByYear, openDealsOnCurrentYear)
  } else {
    finalData = newListByYear
  }

  return finalData
}


// USATO IN Performances.js
export const getWonDealByYear = (marshedResults, currentYear) => {
  var finalData = []
  var processingDeals = []
  var endedDeals = []
  var undefStateDeals = []
  if (!_.isEmpty(currentYear)) {
    _.forEach(marshedResults, el => {
      if (el.develop_state == 'IN CORSO') {
        processingDeals.push(el)
      }
      if ((el.year === currentYear) && (el.develop_state === 'FINITO')) {
        endedDeals.push(el)
      }
      if ((el.year === currentYear) && (_.isNil(el.develop_state))) {
        undefStateDeals.push(el)

      }
    })
    finalData = _.concat(processingDeals, endedDeals, undefStateDeals)
  } else {
    finalData = marshedResults
  }

  return finalData
}

export const workCSV = (assignmentList, currentYear) => {
  let dataForCSV = []
  _.map(assignmentList, (item) => {
    let newAss
    item.year.includes(currentYear)
      ? (newAss = {
        id: item.id,
        organization_name: item.organization_name,
        title: item.title
      })
      : (newAss = {
        id: null
      })

    if (newAss.id) {
      dataForCSV.push(newAss)
    }
  })
  const sortedData = _.sortBy(dataForCSV, 'id')
  return sortedData
}