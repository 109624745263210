import React from 'react';
import styled from 'styled-components';
import logo2 from '../img/logoName.png';

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80vh;
`;

const Logo = () => {
  return (
    <LogoContainer>
      <img src={logo2} alt="Logo" height={'120%'}></img>
    </LogoContainer>
  );
};

export default Logo
