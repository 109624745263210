import StateBadge from '../models/badge'
import { stateFilters, optionsStatus } from '../models/models'
import _ from 'lodash'
import moment from 'moment'

const formatNumber = (number) => {
  const newNumber = new Intl.NumberFormat('it-IT', {
    style: 'currency',
    currency: 'EUR',
    maximumFractionDigits: 0
  }).format(number)

  return newNumber
}

const formatDate = (date) => {
  if (!_.isEmpty(date)) {
    return moment(date).format('L')
  } else {
    return ' '
  }
}

export const dealColumns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    fixed: 'left',
    width: 40,
    sorter: (a, b) => {
      return a.id - b.id
    }
  },
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
    fixed: 'left',
    width: 200,
    render: (record, obj) => {
      return obj.title
    },
    sorter: (a, b) => {
      if (a.title < b.title) {
        return -1;
      }
      if (a.title > b.title) {
        return 1;
      }
      return 0;
    }
  },
  {
    title: 'Project',
    dataIndex: 'project',
    key: 'project',
    width: 150
  },
  {
    title: 'Deal Value',
    dataIndex: 'deal_value',
    key: 'deal_value',
    width: 150,
    render: (record, obj) => {
      return formatNumber(obj.deal_value)
    }
  },
  {
    title: "Organization Name",
    dataIndex: 'organization_name',
    key: 'organization_name',
    width: 150,
  },
  {
    title: 'Contact Person',
    dataIndex: 'contact_person',
    key: 'contact_person',
    width: 150,
  },
  {
    title: 'Expected Close Date',
    dataIndex: 'close_date',
    key: 'close_date',
    width: 150,
    render: (record, obj) => {
      return formatDate(obj.close_date)
    }
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    width: 100,
    filters: optionsStatus,
    filterMode: 'tree',
    filterSearch: true,
    onFilter: (status, record) => {
      return record.status === status
    },
  },
  {
    title: 'Created',
    dataIndex: 'created',
    key: 'created',
    width: 150,
    render: (record, obj) => {
      return formatDate(obj.created)
    },
    sorter: (a, b) => {
      var timeA = 0
      var timeB = 0
      if (!_.isEmpty(a.created)) {
        timeA = moment(a.created).unix()
      }
      if (!_.isEmpty(b.created)) {
        timeB = moment(b.created).unix()
      }
      return timeB - timeA
    }
  },
  {
    title: 'Won Time',
    dataIndex: 'won_time',
    key: 'won_time',
    width: 150,
    render: (record, obj) => {
      return formatDate(obj.won_time)
    },
    sorter: (a, b) => {
      var timeA = 0
      var timeB = 0
      if (!_.isEmpty(a.won_time)) {
        timeA = moment(a.won_time).unix()
      }
      if (!_.isEmpty(b.won_time)) {
        timeB = moment(b.won_time).unix()
      }
      return timeB - timeA
    }
  },
  {
    title: 'Lost Time',
    dataIndex: 'lost_time',
    key: 'lost_time',
    width: 150,
    render: (record, obj) => {
      return formatDate(obj.lost_time)
    },
    sorter: (a, b) => {
      var timeA = 0
      var timeB = 0
      if (!_.isEmpty(a.lost_time)) {
        timeA = moment(a.lost_time).unix()
      }
      if (!_.isEmpty(b.lost_time)) {
        timeB = moment(b.lost_time).unix()
      }
      return timeB - timeA
    }
  },
  {
    title: 'Tipo Commessa',
    dataIndex: 'typology',
    key: 'typology',
    width: 150,
  },
  {
    title: 'Deal Stage',
    dataIndex: 'varState',
    key: 'varState',
    align: 'center',
    fixed: 'right',
    render: (varState, obj) => {
     
      

      return <StateBadge state={varState} />
    },

    filters: stateFilters,
    filterMode: 'tree',
    filterSearch: true,
    onFilter: (value, record) => {
      return record.state === value
    },
    sorter: (a, b) => {

      if (b.state === 'to_signed' && a.state === 'awarded') {
        return 1
      }
      if (a.state === 'to_signed' && b.state === 'awarded') {
        return -1
      }
      if (b.state === 'recognized_opportunity' && a.state === 'to_signed') {
        return -1
      }
      if (a.state === 'recognized_opportunity' && b.state === 'to_signed') {
        return 1
      }
      if (b.state === 'recognized_opportunity' && a.state === 'awarded') {
        return 1
      }
      if (a.state === 'recognized_opportunity' && b.state === 'awarded') {
        return -1
      }
      if (a.state === 'awarded' && b.state === 'postponed') {
        return -1
      }
      if (a.state === 'refused' && b.state === 'postponed') {
        return 1
      }
      if (a.state < b.state) {
        return -1
      }
      if (a.state > b.state) {
        return 1
      }

      return 0
    },
  }
]