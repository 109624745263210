export const optionsStatus = [
  {
    text: 'Won',
    value: 'Won'
  },
  {
    text: 'Lost',
    value: 'Lost'
  },
  {
    text: 'Open',
    value: 'Open'
  }
]

export const stateFilters = [
  {
    text: 'Opportunità Rilevata',
    value: 'recognized_opportunity'
  },
  {
    text: 'Offerta proposta',
    value: 'proposed_offer'
  },
  {
    text: 'Alla Firma',
    value: 'to_signed'
  },
  {
    text: 'Aggiudicato',
    value: 'awarded'
  },
  {
    text: 'Rimandato',
    value: 'postponed'
  },
  
  {
    text: 'Rifiutato',
    value: 'refused'
  },
 
]

export const headersCSV = [
  {
    label: 'ID',
    key: 'id'
  },
  {
    label: 'Nome Azienda',
    key: 'organization_name'
  },
  {
    label: 'Nome Commessa',
    key: 'title'
  }
]
