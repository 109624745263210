import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './components/App';

import { Amplify } from 'aws-amplify';
import awsmobile from './aws-exports';
Amplify.configure({ ...awsmobile, srr: true });

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);
root.render(<App />);
