import React from 'react';
import { Menu } from 'antd';
import { sideMenuItems } from '../datas/SideMenuDatas';
import Logo from '../img/Logo1.png';
import { Link } from 'react-router-dom';

const SideMenu = () => {
  return (
    <>
      <div className="logoDiv" style={{ marginBottom: '10px' }}>
        <Link to="/">
          <img src={Logo} alt="Logo" height={40}></img>
        </Link>
      </div>
      <Menu theme="dark" mode="inline" items={sideMenuItems}></Menu>
    </>
  );
};

export default SideMenu;
